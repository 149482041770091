// Sidebar usuario pagador 
export const menuList = [
    {
        icon: 'o_description',
        label: 'Pronto Pago',
        collapse: false,
        to: 'pronto-pago'

    },
    {
        icon: 'o_description',
        label: 'Confirming',
        collapse: false,
        to:'confirming'

    },
    {
        icon: 'o_archive',
        label: 'Panel de recepción',
        collapse: false,
        to:'panel-recepcion'

    },
    {
        icon: 'o_menu_book',
        label: 'Informes de Carga',
        collapse: false,
        to:'informes'

    },
    {
        icon: 'o_menu_book',
        label: 'Informes de Factura',
        collapse: false,
        to:'informes-financiados'

    },
    {
        icon: 'o_settings',
        label: 'Configuración',
        collapse: true,
        submenu: [
            {
                icon: 'domain',
                label: 'Mis empresas',
                collapse: false,
                to:'empresas'
        
            },
            {
                icon: 'person',
                label: 'Mis usuarios',
                collapse: false,
                to:'users'
            },
            {
                icon: 'trending_up',
                label: 'Tasas de proveedores',
                collapse: false,
                to:'proveedor-tasas'
            },
            // {
            //     icon: 'list_alt',
            //     label: 'Proveedores autorizados',
            //     collapse: false,
            //     to: 'proveedor-autorizado'
            // },
            {
                icon: 'folder_shared',
                label: 'Carga proveedores',
                collapse: false,
                to:'carga-proveedor'
            },
            {
                icon: 'view_list',
                iconColor: 'primary',
                label: 'Lista proovedores',
                collapse: false,
                to:'lista-proveedor'
            }
        ]
    },
]

// Sidebar usuario admin
export const menuListAdmin = [
    {
        icon: 'domain',
        label: 'Empresas',
        collapse: false,
        to:'empresas'

    },
    {
        icon: 'calendar_month',
        label: 'Logs',
        collapse: false,
        to:'logs'

    },
    // {
    //     icon: 'trending_up',
    //     label: 'Tasas de proveedores',
    //     collapse: false,
    //     to:'proveedor-tasas'
    // },
    {
        icon: 'person',
        label: 'Usuarios',
        collapse: false,
        to:'users'
    },
    {
        icon: 'o_settings',
        label: 'Configuración',
        collapse: true,
        submenu: [
            {
                icon: 'calendar_month',
                label: 'Logs',
                collapse: false,
                to:'logs'
        
            },
            {
                icon: 'trending_up',
                label: 'Tasas de proveedores',
                collapse: false,
                to:'proveedor-tasas'
            },
            {
                icon: 'person',
                label: 'Usuarios',
                collapse: false,
                to:'users'
            },
            {
                icon: 'o_build_circle',
                label: 'Parámetros globales',
                collapse: false,
                to: 'parametros-globales'
            },
            {
                icon: 'folder_shared',
                label: 'Carga proveedores',
                collapse: false,
                to:'carga-proveedor'
            },
            {
                icon: 'view_list',
                iconColor: 'primary',
                label: 'Lista proovedores',
                collapse: false,
                to:'lista-proveedor'
            }
        ]
    },
    {
        icon: 'event_note',
        label: 'Auditoria',
        collapse: false,
        to: 'auditoria'
    },
    {
        icon: 'view_list',
        iconColor: 'primary',
        label: 'Lista proovedores',
        collapse: false,
        to:'lista-proveedor'
    },
    {
        icon: 'o_inventory_2',
        label: 'Mantenedor diccionario',
        collapse: false,
        to: 'mantenedor-diccionario'
    }
        
]