import Axios from "axios";
import { Configuration } from "./configuration";
import store from "@/store/index"
//eslint-disable-next-line
// @ts-ignore
import md5 from 'crypto-js/md5'

const instance = Axios.create();
instance.defaults.headers.common = {}
instance.defaults.headers.common['CF-Access-Client-Id'] = '326d2967214a8e85c79265a78f8b8aee.access';
instance.defaults.headers.common['CF-Access-Client-Secret'] = '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0';

const api_key = "key_pp_front_fnPqT5xQEi5Vcb9wKwbCf65c3BjVGyBB";
const api_key_md5 = md5(api_key);

export default{
    // User login
    async login(ruc: string, password: string) {
        const formData = new FormData();
        formData.append('ruc', ruc);
        formData.append('password', password);
        
        return await instance.post(`${Configuration.api.base}/login`, formData)
    },
    // Register
    register(email: string, name:string, password:string, phone:string, telefono:string, clave_sol:string, ruc:string, password_confirmation:string ) {

      const formData = new FormData();
      formData.append('email',email);
      formData.append('ruc', ruc);
      formData.append('nombre', name);
      formData.append('password', password);
      formData.append('celular', phone);
      formData.append('telefono', telefono);
      formData.append('clave_sol', clave_sol);
      formData.append('password_confirmation', password_confirmation);
      
      return instance.post(`${Configuration.api.base}/registrar_usuario`, formData)
    },
    reestablecerPassword(email:string, ruc:string){
      const formData = new FormData();
      formData.append('email', email);
      formData.append('ruc', ruc);

      return instance.post(`${Configuration.api.base}/restablecer_password`, formData);

    },
    forgetPassword(email:string, password:string, token?:string, ruc?:string){
      const formData = new FormData();
      formData.append('email', email);
      formData.append('password', password);
      formData.append('token', token ? token : '');
      formData.append('ruc', ruc ? ruc : '');

      return instance.post(`${Configuration.api.base}/forget_password`, formData);
    },
    passwordExpired(password_expired_id: string, password:string, current_password: string, ruc: string){
      const formData = new FormData();
      formData.append('password_expired_id', password_expired_id);
      formData.append('new_password', password);
      formData.append('current_password', current_password);
      formData.append('ruc', ruc);
      return instance.post(`${Configuration.api.base}/password_expired`, formData);

    },
    logout(){
      return Axios.get(`${Configuration.api.base}/logout`,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
    },

    // empresas
    async listadoEmpresas(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);      
      const empresas = await Axios.post(`${Configuration.api.base}/empresas/listar_empresas`,formData,
      {
        headers: {
          'Authorization': `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      })
      return empresas;
    },

    registarEmpresa(ruc:string, email:string, rsocial:string, user_sunat:string, clave_sol:string, sync_sunat:boolean){
      const formData = new FormData();
      formData.append('nombre', rsocial);
      formData.append('ruc', ruc);
      formData.append('email', email);
      formData.append('razon_social', rsocial);
      formData.append('user_sol', user_sunat);
      formData.append('clave_sol', clave_sol);
      formData.append('sync_sunat', sync_sunat ? '1' : '0');

      const request = Axios.post(`${Configuration.api.base}/empresas/crear_empresa`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
      return request;
    },

    getEmpresa(id:number){
      const formData = new FormData();
      formData.append('id', id.toString());

      const empresa = Axios.post(`${Configuration.api.base}/empresas/editar_empresa`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      })
      return empresa;
    },
    
    actualizarEmpresa(id:number, email:string, rsocial:string, user_sunat:string, clave_sol:string, sync_sunat:boolean, ruc_empresa:string){
      const formData = new FormData();
      formData.append('id', id.toString());
      formData.append('nombre', rsocial);
      formData.append('ruc', ruc_empresa);
      formData.append('email',email);
      formData.append('razon_social',rsocial);

      formData.append('user_sol', user_sunat);
      formData.append('clave_sol', clave_sol);
      formData.append('sync_sunat', sync_sunat ? '1' : '0');

      const request = Axios.post(`${Configuration.api.base}/empresas/actualizar_empresa`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
      return request;
    },
    
    eliminarEmpresa(id:number){
      const formData = new FormData();
      formData.append('id', id.toString());

      const request = Axios.post(`${Configuration.api.base}/empresas/eliminar_empresa`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
      return request;
    },

    async getMisCupos(){

      const formData = new FormData();
      formData.append('api_key', api_key_md5);

      const cupos = await Axios.post(`${Configuration.api.base}/empresas/resumen`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          // "X-API-KEY": api_key_md5
        }
      });
      return cupos;
    },

    // usuarios
    async listadoUsuarios(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const response = await Axios.post(`${Configuration.api.base}/users/listar_usuarios`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          // "X-API-KEY": api_key_md5
        }
      });
      return response;
    },
    // devuelve empresas y roles.
    async getRoles(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);

      const response = await Axios.post(`${Configuration.api.base}/users/listar_tipo_usuario`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          // "X-API-KEY": api_key_md5
        }
      });
      return response.data;
    },

    nuevoUsuario(name:string, email:string, empresa_id:string[], role:string, celular:string, telefono: string, ruc:string ){
      const formData = new FormData();
      formData.append('ruc', ruc);
      formData.append('nombre', name);
      formData.append('empresa_id[]', JSON.stringify(empresa_id));
      formData.append('usuario_tipo_id', role);
      formData.append('email', email);
      formData.append('celular', celular);
      formData.append('telefono', telefono);
      // formData.append('api_key', api_key_md5);
      return Axios.post(`${Configuration.api.base}/users/store`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
                  }
      });
    },

    async getUsuario(id:number){
      const formData = new FormData();
      formData.append('id', id.toString());
      formData.append('api_key', api_key_md5);
      return await Axios.post(`${Configuration.api.base}/users/edit`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          // "X-API-KEY": api_key_md5
        }
      });
    },

    actualizarUsuario(id:number, name:string, email:string, password: string, role:string, telefono:string, telefono_2: string, ruc:string, empresa_id:string[]){
      const formData = new FormData();
      formData.append('nombre', name);
      formData.append('email', email);
      formData.append('password', password);
      formData.append('usuario_tipo_id', role);
      formData.append('celular', telefono);
      formData.append('telefono', telefono_2);
      formData.append('ruc', ruc);
      formData.append('empresa_id[]', JSON.stringify(empresa_id));
      // formData.append('api_key', api_key_md5);

      return Axios.post(`${Configuration.api.base}/users/update/`+ id, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
                  }
      });
    },

    eliminarUsuario(id:number){
      const formData = new FormData();
      formData.append('id', id.toString());
      formData.append('api_key', api_key_md5);

      return Axios.post(`${Configuration.api.base}/users/destroy`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          // "X-API-KEY": api_key_md5
        }
      });
    },

    // proveedores
    async listadoProveedoresGeneral(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const response = await Axios.post(`${Configuration.api.base}/proveedor/listado`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          // "X-API-KEY": api_key_md5
        }
      });
      return response;
    },
    
    async listadoProovedorAutorizado(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const proveedores = await Axios.post(`${Configuration.api.base}/proveedor/autorizados`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          // "X-API-KEY": api_key_md5
        }
      });

      return proveedores;
    },

    registrarProveedor(ruc:string, nombre_empresa:string, email:string, telefono: string, tasa_soles:string, tasa_dolares:string, empresa_id:string, celular:string, banco_id:string, cuenta_id:string, numero_cuenta:string, cci:string, moneda_id: string){
      const formData = new FormData();
      formData.append('ruc', ruc);
      formData.append('nombre_empresa', nombre_empresa);
      formData.append('email', email);
      formData.append('telefono', telefono);
      formData.append('celular', celular);
      formData.append('tasa_soles', tasa_soles);
      formData.append('tasa_dolares', tasa_dolares);
      formData.append('empresa_id', empresa_id);

      formData.append('banco_id', banco_id);
      formData.append('cuenta_tipo_id', cuenta_id);
      formData.append('numero', numero_cuenta);
      formData.append('cci', cci);
      formData.append('moneda_id', moneda_id);
      formData.append('api_key', api_key_md5);      
      return Axios.post(`${Configuration.api.base}/proveedor/guardar_provedor`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
    },
    async empresasCargaProveedor(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const empresas = await Axios.post(`${Configuration.api.base}/proveedor/empresas_usuario/`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          // "X-API-KEY": api_key_md5
        }
      });

      return empresas;
    },
    eliminarProveedor(id:number){
      const formData = new FormData();
      formData.append('id', id.toString());
      formData.append('api_key', api_key_md5);
      return Axios.post(`${Configuration.api.base}/proveedor/eliminar`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          
          // "X-API-KEY": api_key_md5
        }
      });
    },
    cargaMasivaProveedores(archivo:File, empresa_id:string){
      const formData = new FormData();
      formData.append('archivo', archivo);
      formData.append('empresa_id', empresa_id);
      // formData.append('api_key', api_key_md5);
      return Axios.post(`${Configuration.api.base}/proveedor/store`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
    },
    
    obtenerProveedor(id_proveedor:number){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      formData.append('id', id_proveedor.toString());

      return Axios.post(`${Configuration.api.base}/proveedor/editar`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
    }, 

    actualizarProveedor(id:string,ruc:string, nombre:string, email:string, celular:string, telefono:string, tasa_soles:string, tasa_dolares:string,){
      const formData = new FormData();
      formData.append('id', id);
      formData.append('ruc', ruc);
      formData.append('nombre', nombre);
      formData.append('email', email);
      formData.append('celular', celular);
      formData.append('telefono', telefono);
      formData.append('tasa_soles', tasa_soles);
      formData.append('tasa_dolares', tasa_dolares);

      formData.append('api_key', api_key_md5);
      return Axios.post(`${Configuration.api.base}/proveedor/actualizar`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      })
    },
    async getListadoTasasAplicadas(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const tasas = await Axios.post(`${Configuration.api.base}/proveedor/listado_tasas`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          
          // "X-API-KEY": api_key_md5
        }
      });
      return tasas;
    },
    async getTasaAplicada(id:number){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      formData.append('id', id.toString());
      const tasa = await Axios.post(`${Configuration.api.base}/proveedor/tasa`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          
          // "X-API-KEY": api_key_md5
        }
      });
      return tasa
    },
    actualizarTasa(id:string, tasa_soles:string, tasa_dolares:string){
      const formData = new FormData();
      formData.append('id', id);
      formData.append('tasa_soles', tasa_soles);
      formData.append('tasa_dolares', tasa_dolares);
      formData.append('api_key', api_key_md5);

      return Axios.post(`${Configuration.api.base}/proveedor/modificar_tasa`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
                  }
      })
    },

    //get moneda
    async getMonedas(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const monedas = await Axios.post(`${Configuration.api.base}/monedas`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          
          // "X-API-KEY": api_key_md5
        }
      });
      return monedas
    },

    // facturas
    preCargaFacturas(csv:File, tipo_producto:string, header:string, moneda_id:string ){
      const formData = new FormData();
      formData.append('csv_file', csv);
      formData.append('tipo_producto', tipo_producto);
      formData.append('header', header);
      formData.append('moneda_id', moneda_id);
      formData.append('api_key', api_key_md5);

      return Axios.post(`${Configuration.api.base}/producto/pre_import`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
                  }
      })
    },

    importarFactura(csv_id:string,fields:[], header:string){
      const formData = new FormData();
      formData.append('csv_data_file_id', csv_id);
      formData.append('fields[]', JSON.stringify(fields));
      formData.append('header', header);
      formData.append('api_key', api_key_md5);
      formData.append('tipo_documento', '1');

      return Axios.post(`${Configuration.api.base}/producto/importar_facturas`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          
        }
      });
    },
    // auditoria
    async getHistorico(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const historico = await Axios.post(`${Configuration.api.base}/auditoria/`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          
          // "X-API-KEY": api_key_md5
        }
      });
      return historico;
    },

    // informes
    async getFacturasCargadas(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const facturas = await Axios.post(`${Configuration.api.base}/informes/`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          
          // "X-API-KEY": api_key_md5
        }
      });

      return facturas;
    },

    async consultaInformeDetalle(id:number){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      formData.append('id', id.toString());
      const detalle = await Axios.post(`${Configuration.api.base}/informes/consulta`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          
          // "X-API-KEY": api_key_md5
        }
      });
      return detalle;
    },
    async consultaInformeFinanciados(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const detalle = await Axios.post(`${Configuration.api.base}/informes/informes_financiados`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          
          // "X-API-KEY": api_key_md5
        }
      });
      return detalle;
    },
    
    async exportarInforme(id:number){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      formData.append('id', id.toString());
      const exportarInforme = await Axios.post(`${Configuration.api.base}/informes/exportar/`, formData, 
      {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          
          // "X-API-KEY": api_key_md5
        }
      });
      return exportarInforme;
    },

    descartarInforme(id:string){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      formData.append('n_informe', id);

      return Axios.post(`${Configuration.api.base}/informes/descartar_informe`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
                  }
      });
    },

    // fondeadores
    async consultaFondeadores(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      
      const fondeador = await Axios.post(`${Configuration.api.base}/empresas/consulta_fondeadores`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          
          // "X-API-KEY": api_key_md5
        }
      });
      return fondeador;
    },
    // retorna las empresas y las tasas del fondeador
    async getEmpresasFondeador(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const infoFondeador = await Axios.post(`${Configuration.api.base}/fondeadores/datos_fondeador`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });

      return infoFondeador;
    },

    async getAsignacionesFondeador(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const asignaciones = await Axios.post(`${Configuration.api.base}/fondeadores/mis_asignaciones`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
      return asignaciones;
    },
    asignarCupo(empresa_id:string, tipo_tasa:string, cupo:string, tasa:string, fecha_vencimiento:string, dias_maximos_adicionales: string, tasa_hoy:string, tasa_calculada?:string){
      const formData = new FormData();
      formData.append('empresa_id', empresa_id);
      formData.append('tipo_tasa', tipo_tasa);
      formData.append('cupo', cupo);
      formData.append('fecha_vencimiento', fecha_vencimiento);
      formData.append('tasa', tasa);
      formData.append('tasa_hoy', tasa_hoy);
      formData.append('tasa_calculada', tasa_calculada == undefined ? '0' : tasa_calculada);
      formData.append('dias_maximos_adicionales', dias_maximos_adicionales);
      formData.append('api_key', api_key_md5);

      return Axios.post(`${Configuration.api.base}/fondeadores/asignar_cupo`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
                  }
      });
    },
    
    getCupo(id:number){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      formData.append('id', id.toString());
      return Axios.post(`${Configuration.api.base}/fondeadores/editar`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
    },
    
    actualizarCupo(cupo_id:string, tasa:string, cupo:string, fecha_vencimiento:string, dias_maximos_adicionales:string, tipo_tasa:string, tasa_calculada:string){
      const formData = new FormData();
      formData.append('id', cupo_id);
      formData.append('tasa', tasa);
      formData.append('cupo', cupo);
      formData.append('fecha_vencimiento', fecha_vencimiento);
      formData.append('dias_maximos_adicionales', dias_maximos_adicionales);
      formData.append('tipo_tasa', tipo_tasa);
      formData.append('tasa_calculada', tasa_calculada);
      formData.append('api_key', api_key_md5);
      
      return Axios.post(`${Configuration.api.base}/fondeadores/actualizar_cupo`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0', 
                  }
      });
    },
    
    eliminarCupo(id:number){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      formData.append('id', id.toString());
      return Axios.post(`${Configuration.api.base}/fondeadores/eliminar`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
    },
    
    deshabilitarCupo(cupo_id:string, status:string, email:string){
      const formData = new FormData();
      formData.append('id', cupo_id);
      formData.append('status', status);
      formData.append('email', email);
      formData.append('api_key', api_key_md5);
      
      return Axios.post(`${Configuration.api.base}/fondeadores/deshabilitar`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
    },
    updateTasaHoy(tipo_tasa:string, tasa:string){
      // fondeadores/editar_tasa_hoy
      const formData = new FormData();
      formData.append('tipo_tasa', tipo_tasa);
      formData.append('tasa', tasa);
      formData.append('api_key', api_key_md5);

      return Axios.post(`${Configuration.api.base}/fondeadores/editar_tasa_hoy`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
    },

    async getUsuariosFondeador(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const usuarios = await Axios.post(`${Configuration.api.base}/fondeadores/mis_usuarios`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      })
      return usuarios;
    },

    // tasas globales
    async getTasasGlobales(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const tasaGlobal = await Axios.post(`${Configuration.api.base}/proveedor/obtener_tasa_global`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          
          // "X-API-KEY": api_key_md5
        }
      });
      return tasaGlobal;
    },

    updateTasaGlobal(tasa_soles:string, tasa_dolares:string){
      const formData = new FormData();
      formData.append('tasa_soles', tasa_soles);
      formData.append('tasa_dolares', tasa_dolares);
      formData.append('api_key', api_key_md5);

      return Axios.post(`${Configuration.api.base}/proveedor/actualizar_tasa_global`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
                  }
      });
    },

    updateFechaGlobal(tipo_marca:string, dias_posterior_vencimiento:string, dias_limite_vencimiento:string){
      const formData = new FormData();
      formData.append('tipo_marca', tipo_marca);
      formData.append('dias_posterior_vencimiento', dias_posterior_vencimiento);
      formData.append('dias_limite_vencimiento', dias_limite_vencimiento);
      formData.append('api_key', api_key_md5);

      return Axios.post(`${Configuration.api.base}/proveedor/actualizar_fecha_global`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
                  }
      });
    },

    getLogs(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      return Axios.post(`${Configuration.api.base}/logs`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
    },

    // CUENTAS BANCARIAS PROVEEDORES
    getBancos(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      return Axios.post(`${Configuration.api.base}/bancos/obtener_bancos`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
    },

    getTipoCuentasBancos(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      return Axios.post(`${Configuration.api.base}/bancos/obtener_tipo_cuentas`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
    },

    getCuentasProveedor(empresa_id:number, proveedor_id:number){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      formData.append('empresa_id', empresa_id.toString());
      formData.append('proveedor_id', proveedor_id.toString());
      return Axios.post(`${Configuration.api.base}/bancos/obtener_proveedor_cuentas`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
    },
    
    crearCuentaBancariaProveedor(empresa_id:number, proveedor_id:number, banco_id:number, tipo_cuenta_id:number, numero_cuenta:number, cci:string, moneda_id:number){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      formData.append('empresa_id', empresa_id.toString());
      formData.append('proveedor_id', proveedor_id.toString());
      formData.append('banco_id', banco_id.toString());
      formData.append('cuenta_tipo_id', tipo_cuenta_id.toString());
      formData.append('numero', numero_cuenta.toString());
      formData.append('cci', cci);
      formData.append('moneda_id', moneda_id.toString());
      return Axios.post(`${Configuration.api.base}/bancos/crear_proveedor_cuenta`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
    },

    getCuentaProveedorEdit(cuenta_id:number){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      formData.append('cuenta_id', cuenta_id.toString());
      return Axios.post(`${Configuration.api.base}/bancos/editar_proveedor_cuenta`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
    },

    actualizarCuentaBancariaProveedor(empresa_id:number, proveedor_id:number, banco_id:number, tipo_cuenta_id:number, numero_cuenta:number,cuenta_id:number, cci:string, moneda_id:number ){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      formData.append('empresa_id', empresa_id.toString());
      formData.append('proveedor_id', proveedor_id.toString());
      formData.append('banco_id', banco_id.toString());
      formData.append('cuenta_tipo_id', tipo_cuenta_id.toString());
      formData.append('numero', numero_cuenta.toString());
      formData.append('cuenta_id', cuenta_id.toString());
      formData.append('cci', cci);
      formData.append('moneda_id', moneda_id.toString());
      return Axios.post(`${Configuration.api.base}/bancos/actualizar_proveedor_cuenta`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
    },

    actualizarCuentaPredeterminada(id_cuenta:number){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      formData.append('cuenta_id', id_cuenta.toString());
      
      return Axios.post(`${Configuration.api.base}/bancos/actualizar_cuenta_predeterminada `, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
      
    },
    
    //empresas enroladas
    getEmpresasSUNAT(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);

      return Axios.post(`${Configuration.api.base}/facturas_sunat/obtener_empresas `, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
    },
    
    //obtener facturas -> todas las facturas de las empresas enroladas
    getFacturasEmpresasEnroladas(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);

      return Axios.post(`${Configuration.api.base}/facturas_sunat/obtener_facturas `, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
    },

    sincronizarFacturas(empresa_id:string){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      // id empresa seleccionada
      formData.append('id', empresa_id);

      return Axios.post(`${Configuration.api.base}/facturas_sunat/sync_facturas `, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
    },

    //diccionario
    obtenerDiccionario(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      return Axios.post(`${Configuration.api.base}/dictionary/listado`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      });
    },

    obtenerDiccionaroDetalles(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      return Axios.post(`${Configuration.api.base}/dictionary/listado_detalle`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          }
      });
    },

    getDiccionarioDetalle(id:number){
      const formData = new FormData();
      formData.append('id', id.toString());
      formData.append('api_key', api_key_md5);
      return Axios.post(`${Configuration.api.base}/dictionary/edit`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          }
      });
    },

    registrarDiccionario(categoria_id:number, codigo:string, descripcion:string, valor:string){
      const formData = new FormData();
      formData.append('dictionary_id', categoria_id.toString());
      formData.append('code', codigo);
      formData.append('description', descripcion);
      formData.append('value', valor);
      formData.append('api_key', api_key_md5);
      return Axios.post(`${Configuration.api.base}/dictionary/crear`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          }
      });
    },

    actualizarDiccionario(id:number, categoria_id:number, descripcion:string, valor:string){
      const formData = new FormData();
      formData.append('id', id.toString());
      formData.append('dictionary_id', categoria_id.toString());
      formData.append('description', descripcion);
      formData.append('value', valor);
      formData.append('api_key', api_key_md5);
      return Axios.post(`${Configuration.api.base}/dictionary/actualizar`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
          }
      });
    },
    
    changeTutorialVideo(user_id:string){
      const formData = new FormData();
      formData.append('id', user_id);
      formData.append('tutorial', '1');
      formData.append('api_key', api_key_md5);

      return Axios.post(`${Configuration.api.base}/users/tutorial`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'CF-Access-Client-Id': '326d2967214a8e85c79265a78f8b8aee.access',
          'CF-Access-Client-Secret': '4dd749d8b99afde58408d04c42f288a727caa11b25bb399046aa40bb1ef3f6f0',
        }
      })
    },
}